import React, { useEffect, CSSProperties } from 'react';
import { Box, Typography, Tabs, Tab, Button, Paper } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import DocNavigation from '../components/DocNavigation';
import { ArrowBack as ArrowBackIcon, ArrowForward as ArrowForwardIcon } from '@mui/icons-material';
import { CopyBlock, dracula } from 'react-code-blocks';

const styles: { [key: string]: CSSProperties } = {
    container: {
        fontFamily: 'Arial, sans-serif',
        padding: '20px',
        backgroundColor: '#f9f9f9',
        color: '#333',
    },
    section: {
        marginBottom: '20px',
    },
    codeBlock: {
        backgroundColor: '#eee',
        padding: '10px',
        borderRadius: '4px',
        fontFamily: 'monospace',
        overflowX: 'auto',
        whiteSpace: 'pre-wrap',
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
        marginBottom: '20px',
    },
    th: {
        border: '1px solid #ddd',
        padding: '8px',
        textAlign: 'left',
        backgroundColor: '#f2f2f2',
    },
    td: {
        border: '1px solid #ddd',
        padding: '8px',
        textAlign: 'left',
    },
};

const WebSDKIntegration: React.FC = React.memo(() => {
    const location = useLocation();
    const navigate = useNavigate();
    const [selectedTab, setSelectedTab] = React.useState(0);
    const [selectedPlatform, setSelectedPlatform] = React.useState('react-native');

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, [location.pathname]);

    useEffect(() => {
        if (location.hash === '#event-tracking') {
            setSelectedTab(1);
        } else {
            setSelectedTab(0);
        }
        console.log('Current hash:', location.hash);
    }, [location.hash]);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
        navigate(`/web-sdk-integration${newValue === 1 ? '#event-tracking' : '#sdk'}`);
    };

    const handlePlatformChange = (platform: string) => {
        setSelectedPlatform(platform);
    };

    const SDKContent = () => (
        <Box sx={styles.container}>
            <section style={styles.section}>
                <section style={styles.section}>
                    <Typography variant="h6" sx={{ mt: 0, color: "#0b3452", fontWeight: "bold" }}>
                        Overview
                    </Typography>
                    <p>The Finvu WebSocket wrapper library enables seamless interaction between FIUs and Finvu AA. It can be embedded in any web application.</p>

                    <p>CDN Paths:</p>
                    <ul>
                        <li><strong>UAT:</strong> <code>https://reactjssdk.finvu.in/sdk/connector/finvu-client-sdk.js</code></li>
                        <li><strong>Production:</strong> <code>https://webvwlive.finvu.in/sdk/connector/finvu-client-sdk.js</code></li>
                    </ul>
                    <CopyBlock
                        text={`// 1. Include the SDK
<script src="<cdn path>/sdk/connector/finvu-client-sdk.js"></script>

// 2. Initialize and setup connection
<script>
    finvuClient.open();
    
    // 3. Setup encrypted request context
    await finvuClient.fiuInfo(
        "encrypted_request_string",  // Encrypted request from your backend
        "2024-12-13T08:48:52.870Z", // Current timestamp
        "BARB0KIMXXX",              // Financial institution ID
        "FIU"                       // Optional institution type
    );
    
    // 4. Make API calls
    // ... API calls go here
</script>`}
                        language="html"
                        showLineNumbers={true}
                        theme={dracula}
                    />
                </section>

                <section style={styles.section}>
                    <Typography variant="h6" sx={{ mt: 4, color: "#0b3452", fontWeight: "bold" }}>
                        API Documentation
                    </Typography>

                    {/* 1. Decrypting Request Parameters */}
                    <Typography variant="h6" sx={{ mt: 3, color: "#0b3452", fontWeight: "bold" }}>
                        1. Decrypting Request Parameters
                    </Typography>
                    <p>Sets up the encrypted request context required for authentication.</p>

                    <Box sx={{ bgcolor: '#f5f5f5', p: 2, borderRadius: 1, mb: 2 }}>
                        <Typography sx={{ fontFamily: 'monospace', color: '#0b3452' }}>
                            <strong>Method:</strong> finvuClient.fiuInfo(request, timestamp, fi, instType)
                        </Typography>
                        <Typography sx={{ fontFamily: 'monospace', color: '#666', mt: 1, fontSize: '0.9em' }}>
                            Parameters:
                            - request: Encrypted request string
                            - timestamp: ISO format timestamp
                            - fi: Financial institution ID
                            - instType: Optional institution type
                        </Typography>
                    </Box>

                    <h4>Request Schema</h4>
                    <CopyBlock
                        text={`{
    "request": "String",     // Encrypted request string
    "timestamp": "String",   // ISO format timestamp
    "fi": "String",         // Financial institution ID
    "instType": "String"    // Optional institution type
}`}
                        language="json"
                        showLineNumbers={true}
                        theme={dracula}
                    />

                    <h4>Request Example</h4>
                    <CopyBlock
                        text={`finvuClient.fiuInfo(
    encryptedRequest,
    timestamp,
    fiId,
    instType
).then((response) => {
    console.log(response);
});`}
                        language="javascript"
                        showLineNumbers={true}
                        theme={dracula}
                    />

                    <h4>Fields</h4>
                    <ul>
                        <li><strong>request</strong>: Encrypted request string from your backend. <em>(Required)</em></li>
                        <li><strong>timestamp</strong>: Request timestamp in ISO format. <em>(Required)</em></li>
                        <li><strong>fi</strong>: Financial institution identifier. <em>(Required)</em></li>
                        <li><strong>instType</strong>: Institution type (e.g., "FIU"). <em>(Optional)</em></li>
                    </ul>

                    {/* 2. Login Encrypt */}
                    <Typography variant="h6" sx={{ mt: 3, color: "#0b3452", fontWeight: "bold" }}>
                        2. Login Encrypt
                    </Typography>
                    <p>Initiates the encrypted OTP-based login process.</p>

                    <Box sx={{ bgcolor: '#f5f5f5', p: 2, borderRadius: 1, mb: 2 }}>
                        <Typography sx={{ fontFamily: 'monospace', color: '#0b3452' }}>
                            <strong>Method:</strong> finvuClient.loginEncrypt()
                        </Typography>
                    </Box>

                    <h4>Prerequisites</h4>
                    <ul>
                        <li>Must call fiuInfo() first to set up the encrypted request context</li>
                        <li>WebSocket connection must be established</li>
                    </ul>

                    <h4>Request Example</h4>
                    <CopyBlock
                        text={`// First set up the encrypted request context
await finvuClient.fiuInfo(
    encryptedRequest,
    timestamp,
    fiId,
    instType
);

// Then initiate login
finvuClient.loginEncrypt().then((response) => {
    if (response.status === 'SEND') {
        console.log("OTP sent to ", response.maskedId);
    }
});`}
                        language="javascript"
                        showLineNumbers={true}
                        theme={dracula}
                    />

                    <h4>Response Schema</h4>
                    <CopyBlock
                        text={`{
    "header": {
        "mid": "String",     // Message ID
        "ts": "String",      // Timestamp
        "sid": "String",     // Session ID
        "dup": "Boolean",    // Duplicate flag
        "type": "String"     // Response type identifier
    },
    "payload": {
        "status": "SEND",
        "message": "String",
        "userIdOrMobileNo": "String",  // Format: "{number}@finvu"
        "keepAliveTimeout": "Number",
        "keepAliveUrl": "String",
        "redirectUrl": "String",
        "fips": ["String"],  // Array of FIP IDs
        "institutionType": "String",
        "entityInfo": {
            "entityId": "String",
            "entityName": "String",
            "entityIconUri": "String",
            "entityLogoUri": "String",
            "entityLogoWithNameUri": "String"
        },
        "consentFITypes": ["String"]
    }
}`}
                        language="json"
                        showLineNumbers={true}
                        theme={dracula}
                    />

                    <h4>Success Response Example</h4>
                    <CopyBlock
                        text={`{
    "status": "SEND",
    "message": "OTP sent successfully",
    "maskedId": "XXXXXX9178"
}`}
                        language="json"
                        showLineNumbers={true}
                        theme={dracula}
                    />

                    <h4>Error Response Example</h4>
                    <CopyBlock
                        text={`{
    "status": "FAILURE",
    "message": "Encrypted request context not initialized"
}`}
                        language="json"
                        showLineNumbers={true}
                        theme={dracula}
                    />

                    {/* 3. OTP Verification */}
                    <Typography variant="h6" sx={{ mt: 3, color: "#0b3452", fontWeight: "bold" }}>
                        3. OTP Verification
                    </Typography>
                    <p>Verifies the OTP received during the login process.</p>

                    <Box sx={{ bgcolor: '#f5f5f5', p: 2, borderRadius: 1, mb: 2 }}>
                        <Typography sx={{ fontFamily: 'monospace', color: '#0b3452' }}>
                            <strong>Method:</strong> finvuClient.verifyOTP(otp)
                        </Typography>
                        <Typography sx={{ fontFamily: 'monospace', color: '#666', mt: 1, fontSize: '0.9em' }}>
                            Parameters:
                            - otp: OTP received by user
                        </Typography>
                    </Box>

                    <h4>Prerequisites</h4>
                    <ul>
                        <li>Must have received OTP from loginEncrypt()</li>
                        <li>Active session from previous calls</li>
                    </ul>

                    <h4>Request Schema</h4>
                    <CopyBlock
                        text={`{
    "otp": "String"      // OTP received by user
}`}
                        language="json"
                        showLineNumbers={true}
                        theme={dracula}
                    />

                    <h4>Request Example</h4>
                    <CopyBlock
                        text={`finvuClient.verifyOTP(otp).then((response) => {
    if (response.status === 'SUCCESS') {
        console.log("OTP verified successfully");
    }
});`}
                        language="javascript"
                        showLineNumbers={true}
                        theme={dracula}
                    />

                    <h4>Response Schema</h4>
                    <CopyBlock
                        text={`{
    "header": {
        "mid": "String",     // Message ID
        "ts": "String",      // Timestamp
        "sid": "String",     // Session ID
        "dup": "Boolean",    // Duplicate flag
        "type": "String"     // Response type identifier
    },
    "payload": {
        "status": "SUCCESS",
        "message": "String",
        "sessionId": "String"
    }
}`}
                        language="json"
                        showLineNumbers={true}
                        theme={dracula}
                    />

                    <h4>Success Response Example</h4>
                    <CopyBlock
                        text={`{
    "status": "SUCCESS",
    "message": "OTP verified successfully",
    "sessionId": "abc123xyz"
}`}
                        language="json"
                        showLineNumbers={true}
                        theme={dracula}
                    />

                    <h4>Error Response Example</h4>
                    <CopyBlock
                        text={`{
    "status": "FAILURE",
    "message": "Invalid OTP"
}`}
                        language="json"
                        showLineNumbers={true}
                        theme={dracula}
                    />

                    <h4>Error Cases</h4>
                    <ul>
                        <li>Invalid OTP</li>
                        <li>Expired OTP</li>
                        <li>Maximum attempts exceeded</li>
                        <li>Session timeout</li>
                    </ul>

                    {/* 4. User Details Request */}
                    <Typography variant="h6" sx={{ mt: 3, color: "#0b3452", fontWeight: "bold" }}>
                        4. User Details Request
                    </Typography>
                    <p>Fetches details of the currently logged-in user.</p>

                    <Box sx={{ bgcolor: '#f5f5f5', p: 2, borderRadius: 1, mb: 2 }}>
                        <Typography sx={{ fontFamily: 'monospace', color: '#0b3452' }}>
                            <strong>Method:</strong> finvuClient.userDetails()
                        </Typography>
                    </Box>

                    <h4>Request Example</h4>
                    <CopyBlock
                        text={`finvuClient.userDetails().then((response) => {
console.log(response);
});`}
                        language="javascript"
                        showLineNumbers={true}
                        theme={dracula}
                    />

                    <h4>Response Schema</h4>
                    <CopyBlock
                        text={`{
"status": "String",
"message": "String",
"UserInfo": {
    "userId": "String",
    "mobileNo": "String",
    "mobileAuthenticated": "String",
    "emailId": "String",
    "emailAuthenticated": "String"
    }
}`}
                        language="json"
                        showLineNumbers={true}
                        theme={dracula}
                    />

                    <h4>Success Response Example</h4>
                    <CopyBlock
                        text={`{
"status": "ACCEPT",
"message": "Received UserInfo Successfully",
"UserInfo": {
    "userId": "8076729178@finvu",
    "mobileNo": "8076729178",
    "mobileAuthenticated": "Y",
    "emailId": "",
    "emailAuthenticated": "N"
    }
}`}
                        language="json"
                        showLineNumbers={true}
                        theme={dracula}
                    />

                    {/* 5. Mobile Verification Request */}
                    <Typography variant="h6" sx={{ mt: 3, color: "#0b3452", fontWeight: "bold" }}>
                        5. Mobile Verification Request
                    </Typography>
                    <p>Used when discovery/linking is to be performed on a mobile number other than the registered one.</p>

                    <Box sx={{ bgcolor: '#f5f5f5', p: 2, borderRadius: 1, mb: 2 }}>
                        <Typography sx={{ fontFamily: 'monospace', color: '#0b3452' }}>
                            <strong>Method:</strong> finvuClient.mobileVerificationRequest(mobileNumber)
                        </Typography>
                        <Typography sx={{ fontFamily: 'monospace', color: '#666', mt: 1, fontSize: '0.9em' }}>
                            Parameters:
                            - mobileNumber: Mobile number to be verified
                        </Typography>
                    </Box>

                    <h4>Request Schema</h4>
                    <CopyBlock
                        text={`{
    "mobileNum": "String"
}`}
                        language="json"
                        showLineNumbers={true}
                        theme={dracula}
                    />

                    <h4>Request Example</h4>
                    <CopyBlock
                        text={`finvuClient.mobileVerificationRequest(mobileNumber).then((response) => {
console.log(response);
});`}
                        language="javascript"
                        showLineNumbers={true}
                        theme={dracula}
                    />

                    <h4>Fields</h4>
                    <ul>
                        <li><strong>mobileNum</strong>: The mobile number to be verified. <em>(Required)</em></li>
                    </ul>

                    <h4>Response Schema</h4>
                    <CopyBlock
                        text={`{
    "Status": "String",
    "message": "String"
}`}
                        language="json"
                        showLineNumbers={true}
                        theme={dracula}
                    />

                    {/* 6. Mobile Verification Verify Request */}
                    <Typography variant="h6" sx={{ mt: 3, color: "#0b3452", fontWeight: "bold" }}>
                        6. Mobile Verification Verify Request
                    </Typography>
                    <p>Verifies the OTP sent to the mobile number for verification.</p>

                    <Box sx={{ bgcolor: '#f5f5f5', p: 2, borderRadius: 1, mb: 2 }}>
                        <Typography sx={{ fontFamily: 'monospace', color: '#0b3452' }}>
                            <strong>Method:</strong> finvuClient.mobileVerificationVerifyRequest(mobileNumber, otp)
                        </Typography>
                        <Typography sx={{ fontFamily: 'monospace', color: '#666', mt: 1, fontSize: '0.9em' }}>
                            Parameters:
                            - mobileNumber: Mobile number being verified
                            - otp: OTP received on mobile
                        </Typography>
                    </Box>

                    <h4>Request Schema</h4>
                    <CopyBlock
                        text={`{
    "mobileNum": "String",
    "otp": "String"
}`}
                        language="json"
                        showLineNumbers={true}
                        theme={dracula}
                    />

                    <h4>Request Example</h4>
                    <CopyBlock
                        text={`finvuClient.mobileVerificationVerifyRequest(mobileNumber, otp).then((response) => {
console.log(response);
});`}
                        language="javascript"
                        showLineNumbers={true}
                        theme={dracula}
                    />

                    <h4>Fields</h4>
                    <ul>
                        <li><strong>mobileNum</strong>: The mobile number to be verified. <em>(Required)</em></li>
                        <li><strong>otp</strong>: OTP sent to the user's mobile number. <em>(Required)</em></li>
                    </ul>
                    {/* 7. User Linked Accounts */}
                    <Typography variant="h6" sx={{ mt: 3, color: "#0b3452", fontWeight: "bold" }}>
                        7. User Linked Accounts
                    </Typography>
                    <p>Fetches the list of accounts already linked to the user.</p>

                    <Box sx={{ bgcolor: '#f5f5f5', p: 2, borderRadius: 1, mb: 2 }}>
                        <Typography sx={{ fontFamily: 'monospace', color: '#0b3452' }}>
                            <strong>Method:</strong> finvuClient.userLinkedAccounts()
                        </Typography>
                    </Box>

                    <h4>Request Example</h4>
                    <CopyBlock
                        text={`finvuClient.userLinkedAccounts().then((response) => {
console.log(response);
});`}
                        language="javascript"
                        showLineNumbers={true}
                        theme={dracula}
                    />

                    <h4>Response Schema</h4>
                    <CopyBlock
                        text={`{
    "status": "String",
    "message": "String",
    "LinkedAccounts": "Array of Linked Account Objects"
}`}
                        language="json"
                        showLineNumbers={true}
                        theme={dracula}
                    />

                    <h4>Success Response Example</h4>
                    <CopyBlock
                        text={`{
"status": "SUCCESS",
"message": null,
"LinkedAccounts": [
        {
            "userId": "finvudemo@finvu",
            "fipId": "BARB0KIMXXX",
            "fipName": "Finvu Bank",
            "maskedAccNumber": "XXXXXXX3054",
            "accType": "CURRENT"
        }
    ]
}`}
                        language="json"
                        showLineNumbers={true}
                        theme={dracula}
                    />

                    {/* 8. Account Discovery */}
                    <Typography variant="h6" sx={{ mt: 3, color: "#0b3452", fontWeight: "bold" }}>
                        8. Account Discovery
                    </Typography>
                    <p>Discovers accounts associated with a given FIP.</p>

                    <Box sx={{ bgcolor: '#f5f5f5', p: 2, borderRadius: 1, mb: 2 }}>
                        <Typography sx={{ fontFamily: 'monospace', color: '#0b3452' }}>
                            <strong>Method:</strong> finvuClient.discoverAccounts(fipid, identifiers)
                        </Typography>
                        <Typography sx={{ fontFamily: 'monospace', color: '#666', mt: 1, fontSize: '0.9em' }}>
                            Parameters:
                            - fipid: FIP ID as per central registry
                            - identifiers: List of identifiers (e.g., mobile numbers)
                        </Typography>
                    </Box>

                    <h4>Request Schema</h4>
                    <CopyBlock
                        text={`{
    "fipid": "String",
    "identifiers": "Array of Identifier Objects"
}`}
                        language="json"
                        showLineNumbers={true}
                        theme={dracula}
                    />

                    <h4>Request Example</h4>
                    <CopyBlock
                        text={`finvuClient.discoverAccounts(fipid, identifiers).then((response) => {
console.log(response);
});`}
                        language="javascript"
                        showLineNumbers={true}
                        theme={dracula}
                    />

                    <h4>Fields</h4>
                    <ul>
                        <li><strong>fipid</strong>: FIP ID as per the central registry. <em>(Required)</em></li>
                        <li><strong>identifiers</strong>: List of identifiers such as mobile numbers. <em>(Required)</em></li>
                    </ul>

                    <h4>Response Schema</h4>
                    <CopyBlock
                        text={`{
    "status": "String",
    "message": "String",
    "DiscoveredAccounts": "Array of Account Objects"
}`}
                        language="json"
                        showLineNumbers={true}
                        theme={dracula}
                    />

                    <h4>Success Response Example</h4>
                    <CopyBlock
                        text={`{
"status": "SUCCESS",
"message": null,
"DiscoveredAccounts": [
        {
            "accType": "SAVINGS",
            "accRefNumber": "BOB352467108",
            "maskedAccNumber": "XXXXXXXXX2108",
            "FIType": "DEPOSIT"
        }
    ]
}`}
                        language="json"
                        showLineNumbers={true}
                        theme={dracula}
                    />

                    <h4>Error Responses</h4>
                    <CopyBlock
                        text={`{
    "Status": "FAILURE",
    "message": "Invalid identifier"
}`}
                        language="json"
                        showLineNumbers={true}
                        theme={dracula}
                    />

                    {/* 9. Account Linking */}
                    <Typography variant="h6" sx={{ mt: 3, color: "#0b3452", fontWeight: "bold" }}>
                        9. Account Linking
                    </Typography>
                    <p>Links the user's accounts discovered during the discovery process.</p>

                    <Box sx={{ bgcolor: '#f5f5f5', p: 2, borderRadius: 1, mb: 2 }}>
                        <Typography sx={{ fontFamily: 'monospace', color: '#0b3452' }}>
                            <strong>Method:</strong> finvuClient.accountLinking(fipid, account)
                        </Typography>
                        <Typography sx={{ fontFamily: 'monospace', color: '#666', mt: 1, fontSize: '0.9em' }}>
                            Parameters:
                            - fipid: FIP ID as per central registry
                            - account: List of accounts to link
                        </Typography>
                    </Box>

                    <h4>Request Schema</h4>
                    <CopyBlock
                        text={`{
    "fipid": "String",
    "account": "Array of Account Objects"
}`}
                        language="json"
                        showLineNumbers={true}
                        theme={dracula}
                    />

                    <h4>Request Example</h4>
                    <CopyBlock
                        text={`finvuClient.accountLinking(fipid, account).then((response) => {
console.log(response);
});`}
                        language="javascript"
                        showLineNumbers={true}
                        theme={dracula}
                    />

                    <h4>Fields</h4>
                    <ul>
                        <li><strong>fipid</strong>: FIP ID as per the central registry. <em>(Required)</em></li>
                        <li><strong>account</strong>: List of accounts to link. Must include fields such as maskedAccNumber, accRefNumber, FIType, and accType. <em>(Required)</em></li>
                    </ul>

                    <h4>Response Schema</h4>
                    <CopyBlock
                        text={`{
    "Status": "String",
    "message": "String"
}`}
                        language="json"
                        showLineNumbers={true}
                        theme={dracula}
                    />
                    <h4>Success Response Example</h4>
                    <CopyBlock
                        text={`{
    "Status": "SUCCESS",
    "message": ""
}`}
                        language="json"
                        showLineNumbers={true}
                        theme={dracula}
                    />

                    <h4>Error Responses</h4>
                    <CopyBlock
                        text={`{
    "Status": "FAILURE",
    "message": "Invalid FIPID"
}`}
                        language="json"
                        showLineNumbers={true}
                        theme={dracula}
                    />

                    {/* 10. Account Confirm Linking */}
                    <Typography variant="h6" sx={{ mt: 3, color: "#0b3452", fontWeight: "bold" }}>
                        10. Account Confirm Linking
                    </Typography>
                    <p>Confirms the linking of a user's account using the linking reference number and OTP.</p>

                    <Box sx={{ bgcolor: '#f5f5f5', p: 2, borderRadius: 1, mb: 2 }}>
                        <Typography sx={{ fontFamily: 'monospace', color: '#0b3452' }}>
                            <strong>Method:</strong> finvuClient.accountConfirmLinking(accountLinkRefNumber, otp)
                        </Typography>
                        <Typography sx={{ fontFamily: 'monospace', color: '#666', mt: 1, fontSize: '0.9em' }}>
                            Parameters:
                            - accountLinkRefNumber: Linking reference number
                            - otp: OTP received for confirmation
                        </Typography>
                    </Box>

                    <h4>Request Schema</h4>
                    <CopyBlock
                        text={`{
    "accountLinkRefNumber": "String",
    "otp": "String"
}`}
                        language="json"
                        showLineNumbers={true}
                        theme={dracula}
                    />

                    <h4>Request Example</h4>
                    <CopyBlock
                        text={`finvuClient.accountConfirmLinking(accountLinkRefNumber, otp).then((response) => {
console.log(response);
});`}
                        language="javascript"
                        showLineNumbers={true}
                        theme={dracula}
                    />

                    <h4>Fields</h4>
                    <ul>
                        <li><strong>accountLinkRefNumber</strong>: Linking reference number returned in the linking request. <em>(Required)</em></li>
                        <li><strong>otp</strong>: OTP sent to the user's mobile number. <em>(Required)</em></li>
                    </ul>
                    <h4>Response Schema</h4>
                    <CopyBlock
                        text={`{
    "Status": "String",
    "message": "String"
}`}
                        language="json"
                        showLineNumbers={true}
                        theme={dracula}
                    />

            {/* 11. Get Fip List */}
            <Typography variant="h6" sx={{ mt: 3, color: "#0b3452", fontWeight: "bold" }}>
                11. Get FIP List
            </Typography>
            <p>Retrieves a list of popular Financial Information Providers (FIPs) that users frequently interact with.</p>

            <Box sx={{ bgcolor: '#f5f5f5', p: 2, borderRadius: 1, mb: 2 }}>
                <Typography sx={{ fontFamily: 'monospace', color: '#0b3452' }}>
                    <strong>Method:</strong> finvuClient.popularFipList()
                </Typography>
            </Box>

            <h4>Request Example</h4>
            <CopyBlock
                text={`finvuClient.popularFipList().then((response) => {
    if (response.payload.status === "SUCCESS") {
        const popularFips = response.payload.poularSearch;
        // Process the popular FIPs
    }
});`}
                language="javascript"
                showLineNumbers={true}
                theme={dracula}
            />

            <h4>Response Schema</h4>
            <CopyBlock
                text={`{
    "header": {
        "mid": "String",     // Message ID
        "ts": "String",      // Timestamp
        "sid": "String",     // Session ID
        "dup": "Boolean",    // Duplicate flag
        "type": "urn:finvu:in:app:res.popularSearch.01"
    },
    "payload": {
        "status": "SUCCESS",
        "message": null,
        "poularSearch": [    // Array of popular FIP objects
            {
                "fipId": "String",         // FIP identifier
                "productName": "String",    // FIP name
                "fipFitypes": ["String"],   // Supported financial types
                "fipFsr": "String",        // Financial sector regulator
                "productDesc": "String",    // Product description
                "productIconUri": "String", // Icon URL
                "enabled": "Boolean"        // Availability status
            }
        ]
    }
}`}
                language="json"
                showLineNumbers={true}
                theme={dracula}
            />

            <h4>Success Response Example</h4>
            <CopyBlock
                text={`{
    "header": {
        "mid": "0d154edb-9807-4e6c-a02b-2093696a7b37",
        "ts": "2024-12-09T13:23:11.229+00:00",
        "sid": "01JENSD3PB49F8434HDQ3J9HCX",
        "dup": false,
        "type": "urn:finvu:in:app:res.popularSearch.01"
    },
    "payload": {
        "status": "SUCCESS",
        "message": null,
        "poularSearch": [
            {
                "fipId": "IBFIP",
                "productName": "Indian Bank",
                "fipFitypes": [
                    "DEPOSIT",
                    "RECURRING_DEPOSIT",
                    "TERM-DEPOSIT"
                ],
                "fipFsr": "RBI",
                "productDesc": "Indian Bank",
                "productIconUri": "https://cdn.finvu.in/finvulogos/indian-bank-logo.png",
                "enabled": true
            }
        ]
    }
}`}
                language="json"
                showLineNumbers={true}
                theme={dracula}
            />

            <h4>Additional Notes</h4>
            <ul>
                <li>This endpoint retrieves a list of popular Financial Information Providers (FIPs)</li>
                <li>The response includes detailed information about each FIP including supported financial types</li>
                <li>Each FIP entry includes its identifier, name, description, and icon URL</li>
                <li>The fipFsr field indicates the regulatory body overseeing the FIP</li>
            </ul>
                    {/* 12. Consent Request Details */}
                    <Typography variant="h6" sx={{ mt: 3, color: "#0b3452", fontWeight: "bold" }}>
                        12. Consent Request Details
                    </Typography>
                    <p>Fetches details about a consent request, such as purpose, accounts, and duration.</p>

                    <Box sx={{ bgcolor: '#f5f5f5', p: 2, borderRadius: 1, mb: 2 }}>
                        <Typography sx={{ fontFamily: 'monospace', color: '#0b3452' }}>
                            <strong>Method:</strong> finvuClient.consentRequestDetails()
                        </Typography>
                    </Box>

                    <h4>Request Example</h4>
                    <CopyBlock
                        text={`finvuClient.consentRequestDetails().then((response) => {
console.log(response);
});`}
                        language="javascript"
                        showLineNumbers={true}
                        theme={dracula}
                    />

                    <h4>Response Schema</h4>
                    <CopyBlock
                        text={`{
"header": {
    "mid": "String",
    "ts": "String",
    "sid": "String",
    "dup": "Boolean",
    "type": "String"
},
"payload": {
    "status": "String",
    "message": "String",
    "consentId": "String",
    "ver": "String",
    "txnid": "String",
    "consentStatus": "String",
    "createTime": "String",
    "startTime": "String",
    "expireTime": "String",
    "statusLastupdateTimestamp": "String",
    "FIP": "Object",
    "AA": "Object",
    "FIU": "Object",
    "User": "Object",
    "Accounts": "Array",
    "ConsentUse": "Object",
    "DataAccess": "Object",
    "Purpose": "Object",
    "Signature": "Object",
    "mode": "String",
    "fetchType": "String",
    "consentTypes": "Array",
    "consentDisplayDescriptions": "Object",
    "fiTypes": "Array",
    "DataDateTimeRange": "Object",
    "DataLife": "Object",
    "Frequency": "Object",
    "DataFilter": "Array",
    "consentDetailDigitalSignature": "String",
    "ConsentHandle": "Object"
    }
}`}
                        language="json"
                        showLineNumbers={true}
                        theme={dracula}
                    />
                    <h4>Success Response Example</h4>
                    <CopyBlock
                        text={`{
"header": {
    "mid": "1234567890",
    "ts": "2025-01-21T10:30:45.123Z",
    "sid": "SESSION123456",
    "dup": false,
    "type": "CONSENT_RESPONSE"
  },
  "payload": {
    "status": "SUCCESS",
    "message": null,
    "consentId": "e5601e50-ea04-4fb9-a3ef-6377048a98ff",
    "ver": "1.0",
    "txnid": "TXN987654321",
    "consentStatus": "ACTIVE",
    "createTime": "2025-01-21T10:30:45.123Z",
    "startTime": "2025-01-21T10:30:45.123Z",
    "expireTime": "2025-01-22T10:30:45.123Z",
    "statusLastupdateTimestamp": "2025-01-21T10:30:45.123Z",
    "FIP": {
      "id": "FIP123456",
      "name": "Example Bank Ltd"
    },
    "AA": {
      "id": "AA123456",
      "name": "Account Aggregator"
    },
    "FIU": {
      "id": "FIU123456",
      "name": "Financial Information User"
    },
    "User": {
      "id": "USER123456",
      "name": "John Doe"
    },
    "Accounts": [
      {
        "id": "ACC123456",
        "type": "SAVINGS",
        "maskedAccNumber": "XXXX1234"
      }
    ],
    "ConsentUse": {
      "count": 1,
      "lastUsed": "2025-01-21T10:30:45.123Z"
    },
    "DataAccess": {
      "purpose": "Account monitoring",
      "accessType": "ONE_TIME"
    },
    "Purpose": {
      "code": "105",
      "text": "Explicit one-time consent for the accounts",
      "Category": {
        "type": "Account Query and Monitoring"
      }
    },
    "Signature": {
      "signature": "BASE64_ENCODED_SIGNATURE",
      "timestamp": "2025-01-21T10:30:45.123Z"
    },
    "mode": "VIEW",
    "fetchType": "ONETIME",
    "consentTypes": ["TRANSACTIONS", "PROFILE", "SUMMARY"],
    "consentDisplayDescriptions": {
      "title": "Account Information Access",
      "description": "One-time access to account information"
    },
    "fiTypes": ["DEPOSIT"],
    "DataDateTimeRange": {
      "from": "2025-01-20T00:00:00.000Z",
      "to": "2025-01-21T23:59:59.999Z"
    },
    "DataLife": {
      "unit": "DAY",
      "value": 1
    },
    "Frequency": {
      "unit": "YEAR",
      "value": 0
    },
    "DataFilter": [
      {
        "type": "TRANSACTIONAMOUNT",
        "operator": ">",
        "value": "0"
      }
    ],
    "consentDetailDigitalSignature": "BASE64_ENCODED_DIGITAL_SIGNATURE",
    "ConsentHandle": {
      "id": "HANDLE123456",
      "status": "ACTIVE"
    }
  }
}`}
                        language="json"
                        showLineNumbers={true}
                        theme={dracula}
                    />

                    <h4>Error Responses</h4>
                    <CopyBlock
                        text={`{
    "Status": "FAILURE",
    "message": "Consent not found"
}`}
                        language="json"
                        showLineNumbers={true}
                        theme={dracula}
                    />

                    {/* 13. Consent Approval/Denial Request */}
                    <Typography variant="h6" sx={{ mt: 3, color: "#0b3452", fontWeight: "bold" }}>
                        13. Consent Approval/Denial Request
                    </Typography>
                    <p>Handles the approval or denial of a consent request.</p>

                    <Box sx={{ bgcolor: '#f5f5f5', p: 2, borderRadius: 1, mb: 2 }}>
                        <Typography sx={{ fontFamily: 'monospace', color: '#0b3452' }}>
                            <strong>Method:</strong> finvuClient.consentApproveRequest(FIPDetails, handleStatus)
                        </Typography>
                        <Typography sx={{ fontFamily: 'monospace', color: '#666', mt: 1, fontSize: '0.9em' }}>
                            Parameters:
                            - FIPDetails: Array of FIP details objects
                            - handleStatus: "ACCEPT" | "DENY"
                        </Typography>
                    </Box>

                    <h4>Request Schema</h4>
                    <CopyBlock
                        text={`{
    "FIPDetails": "Array of FIP Details Objects",
    "handleStatus": "String (ACCEPT/DENY)"
}`}
                        language="json"
                        showLineNumbers={true}
                        theme={dracula}
                    />
                    <h4>Request Example</h4>
                    <CopyBlock
                        text={`finvuClient.consentApproveRequest(FIPDetails, handleStatus).then((response) => {
console.log(response);
});`}
                        language="javascript"
                        showLineNumbers={true}
                        theme={dracula}
                    />

                    <h4>Fields</h4>
                    <ul>
                        <li><strong>FIPDetails</strong>: Details of the FIP and accounts for which consent is being approved or denied. <em>(Required)</em></li>
                        <li><strong>handleStatus</strong>: ACCEPT or DENY the consent request. <em>(Required)</em></li>
                    </ul>

                    <h4>Response Schema</h4>
                    <CopyBlock
                        text={`{
    "status": "String",
    "message": "String",
    "consentId": "String"
}`}
                        language="json"
                        showLineNumbers={true}
                        theme={dracula}
                    />

                    <h4>Success Response Example</h4>
                    <CopyBlock
                        text={`{
    "status": "SUCCESS",
    "message": null,
    "consentId": "e5601e50-ea04-4fb9-a3ef-6377048a98ff"
}`}
                        language="json"
                        showLineNumbers={true}
                        theme={dracula}
                    />

                    <h4>Error Responses</h4>
                    <CopyBlock
                        text={`{
    "Status": "FAILURE",
    "message": "Invalid consent details"
}`}
                        language="json"
                        showLineNumbers={true}
                        theme={dracula}
                    />

            {/* 14. Generate Response Parameters */}
            <Typography variant="h6" sx={{ mt: 3, color: "#0b3452", fontWeight: "bold" }}>
                14. Generate Response Parameters
            </Typography>
            <p>Called at the end of the redirection journey to encrypt the final response and provide redirection information back to the FIU application.</p>

            <Box sx={{ bgcolor: '#f5f5f5', p: 2, borderRadius: 1, mb: 2 }}>
                <Typography sx={{ fontFamily: 'monospace', color: '#0b3452' }}>
                    <strong>Method:</strong> finvuClient.encryptRequest(errorCode)
                </Typography>
                <Typography sx={{ fontFamily: 'monospace', color: '#666', mt: 1, fontSize: '0.9em' }}>
                    Parameters:
                    - errorCode: Status code indicating journey result
                </Typography>
            </Box>

            <h4>Request Schema</h4>
            <CopyBlock
                text={`{
    "errorCode": "String"    // Status code indicating journey result
}`}
                language="json"
                showLineNumbers={true}
                theme={dracula}
            />

            <h4>Request Example</h4>
            <CopyBlock
                text={`// At the end of the redirection journey
const response = await finvuClient.encryptRequest(0);
if (response.payload.status === "SUCCESS") {
    // 1. Get the encrypted response for the FIU
    const encryptedResult = response.payload.encryptedResponse;
    
    // 2. Get the URL to return to the FIU application
    const returnUrl = response.payload.redirectUrl;
    
    // 3. Redirect back to the FIU application
    window.location.href = returnUrl;
}`}
                language="javascript"
                showLineNumbers={true}
                theme={dracula}
            />

            <h4>Response Schema</h4>
            <CopyBlock
                text={`{
    "header": {
        "mid": "String",     // Message ID
        "ts": "String",      // Timestamp
        "sid": "String",     // Session ID
        "dup": "Boolean",    // Duplicate flag
        "type": "urn:finvu:in:app:res.webViewEncrypt.01"
    },
    "payload": {
        "status": "SUCCESS",
        "message": null,
        "encryptedResponse": "String", // Encrypted journey result
        "responseDate": "String",      // Response timestamp
        "encryptedFiId": "String",     // Encrypted FI identifier
        "redirectUrl": "String",       // Return URL for FIU application
        "fiuName": "String"           // FIU name for display
    }
}`}
                language="json"
                showLineNumbers={true}
                theme={dracula}
            />

            <h4>Error Cases</h4>
            <ul>
                <li>Journey status code is missing</li>
                <li>Session has expired</li>
                <li>Invalid journey state</li>
            </ul>

            <h4>Additional Notes</h4>
            <ul>
                <li>This API should be called only after completing all necessary steps in the redirection journey</li>
                <li>The encrypted response contains all necessary information about the journey's outcome</li>
                <li>The redirectUrl should be used to return the user to the FIU application</li>
                <li>The journey status is encrypted to maintain security during the redirect</li>
            </ul>
                    {/* 15. Logout */}
                    <Typography variant="h6" sx={{ mt: 3, color: "#0b3452", fontWeight: "bold" }}>
                        15. Logout
                    </Typography>
                    <p>Logs out the user and closes the WebSocket connection.</p>

                    <Box sx={{ bgcolor: '#f5f5f5', p: 2, borderRadius: 1, mb: 2 }}>
                        <Typography sx={{ fontFamily: 'monospace', color: '#0b3452' }}>
                            <strong>Method:</strong> finvuClient.logout()
                        </Typography>
                    </Box>

                    <h4>Request Example</h4>
                    <CopyBlock
                        text={`finvuClient.logout().then((response) => {
console.log(response);
});`}
                        language="javascript"
                        showLineNumbers={true}
                        theme={dracula}
                    />

                    <h4>Response Schema</h4>
                    <CopyBlock
                        text={`{
    "Status": "String",
    "message": "String"
}`}
                        language="json"
                        showLineNumbers={true}
                        theme={dracula}
                    />

                    <h4>Success Response Example</h4>
                    <CopyBlock
                        text={`{
    "Status": "SUCCESS",
    "message": "Successfully Logout"
}`}
                        language="json"
                        showLineNumbers={true}
                        theme={dracula}
                    />

                    <h4>Error Responses</h4>
                    <CopyBlock
                        text={`{
    "Status": "FAILURE",
    "message": "Already logged out"
}`}
                        language="json"
                        showLineNumbers={true}
                        theme={dracula}
                    />

                </section>

                {/* Rest of the content from SDKIntegration.tsx */}
            </section>
        </Box>
    );

    const EventTrackingContent = () => (
        <Box sx={styles.container}>
            <section style={styles.section}>
                <Typography variant="h6" sx={{ mt: 0, color: "#0b3452", fontWeight: "bold" }}>
                    Overview
                </Typography>
                <p>The Finvu Event Tracking Library provides standardized event tracking capabilities for Finvu's Account Aggregator frontend applications. It works seamlessly with the existing Finvu Client SDK without requiring any changes to your current implementation.</p>
            </section>


            <section style={styles.section}>
                <Typography variant="h6" sx={{ mt: 4, color: "#0b3452", fontWeight: "bold" }}>
                    Getting Started
                </Typography>
                
                <Typography variant="subtitle1" sx={{ mt: 2, color: "#0b3452", fontWeight: "bold" }}>
                    Prerequisites
                </Typography>
                
                <Typography variant="subtitle1" sx={{ mt: 2, color: "#0b3452", fontWeight: "bold" }}>
                    Platform Integration Setup
                </Typography>
                
            <section style={styles.section}>
                <Typography variant="h6" sx={{ mt: 4, color: "#0b3452", fontWeight: "bold" }}>
                    Platform Integration Setup
                </Typography>

                <Box sx={{ mt: 3 }}>
                    <Paper sx={{ 
                        borderRadius: '4px',
                        overflow: 'hidden'
                    }}>
                        {/* Platform Selector */}
                        <Box sx={{ 
                            borderBottom: '1px solid rgba(0,0,0,0.12)',
                            display: 'flex',
                            gap: 1,
                            p: 1
                        }}>
                            {['React Native', 'Android', 'iOS', 'Iframe'].map((platform) => (
                                <Typography
                                    key={platform.toLowerCase().replace(' ', '-')}
                                    onClick={() => handlePlatformChange(platform.toLowerCase().replace(' ', '-'))}
                                    sx={{
                                        px: 3,
                                        py: 1.5,
                                        cursor: 'pointer',
                                        borderRadius: '4px',
                                        fontSize: '0.95rem',
                                        fontWeight: selectedPlatform === platform.toLowerCase().replace(' ', '-') ? 600 : 400,
                                        color: selectedPlatform === platform.toLowerCase().replace(' ', '-') ? '#0b3452' : '#666',
                                        bgcolor: selectedPlatform === platform.toLowerCase().replace(' ', '-') ? 'rgba(11, 52, 82, 0.08)' : 'transparent',
                                        transition: 'all 0.2s ease',
                                        '&:hover': {
                                            bgcolor: 'rgba(11, 52, 82, 0.05)',
                                            color: '#0b3452'
                                        }
                                    }}
                                >
                                    {platform}
                                </Typography>
                            ))}
                        </Box>

                        {/* Integration Content */}
                        <Box sx={{ p: 2 }}>
                            {selectedPlatform === 'react-native' && (
                                <>
                                    <Typography sx={{ mb: 2 }}>
                                        React Native WebView Integration:
                                    </Typography>
                                    <CopyBlock
                                        text={`import { WebView } from 'react-native-webview';

class FinvuWebView extends Component {
    onMessage(e) {
        // Handle tracking events here
        console.log(e.nativeEvent.data);
    }

    render() {
        return (
            <WebView
                source={{ uri: 'YOUR_FINVU_URL' }}
                javaScriptEnabled={true}
                onMessage={this.onMessage.bind(this)}
                domStorageEnabled={true}
            />
        );
    }
}`}
                                        language="javascript"
                                        showLineNumbers={true}
                                        theme={dracula}
                                    />
                                </>
                            )}
                            {selectedPlatform === 'android' && (
                                <>
                                    <Typography sx={{ mb: 2 }}>
                                        Android WebView Integration:
                                    </Typography>
                                    <CopyBlock
                                        text={`class MainActivity : AppCompatActivity() {
    private lateinit var webView: WebView

    override fun onCreate(savedInstanceState: Bundle?) {
        super.onCreate(savedInstanceState)

        webView = WebView(this).apply {
            settings.javaScriptEnabled = true
            addJavascriptInterface(object : Any() {
                @JavascriptInterface
                fun postMessage(eventData: String) {
                    // Process tracking event data
                    Log.d("FinvuTracking", eventData)
                }
            }, "FinvuAndroid")
        }

        webView.loadUrl("YOUR_FINVU_URL")
        setContentView(webView)
    }
}`}
                                        language="kotlin"
                                        showLineNumbers={true}
                                        theme={dracula}
                                    />
                                </>
                            )}
                            {selectedPlatform === 'ios' && (
                                <>
                                    <Typography sx={{ mb: 2 }}>
                                        iOS WKWebView Integration:
                                    </Typography>
                                    <CopyBlock
                                        text={`class ViewController: UIViewController {
    var webView: WKWebView!

    override func viewDidLoad() {
        super.viewDidLoad()

        // Configure WebView
        let config = WKWebViewConfiguration()
        config.preferences.javaScriptEnabled = true
        let contentController = config.userContentController
        contentController.add(self, name: "FinvuIOS")

        webView = WKWebView(frame: view.bounds, configuration: config)
        view.addSubview(webView)

        // Load Finvu URL
        if let url = URL(string: "YOUR_FINVU_URL") {
            webView.load(URLRequest(url: url))
        }
    }
}`}
                                        language="swift"
                                        showLineNumbers={true}
                                        theme={dracula}
                                    />
                                </>
                            )}
                            {selectedPlatform === 'iframe' && (
                                <>
                                    <Typography sx={{ mb: 2 }}>
                                        Iframe Integration:
                                    </Typography>
                                    <CopyBlock
                                        text={`<iframe
    id="finvuFrame"
    src="YOUR_FINVU_URL"
    style="width: 100%; height: 100%; border: none;"
></iframe>
<script>
    window.addEventListener('message', function(event) {
        if (event.origin !== 'https://your-finvu-domain.com') return;
        console.log(event.data);
    }, false);
</script>`}
                                        language="html"
                                        showLineNumbers={true}
                                        theme={dracula}
                                    />
                                </>
                            )}
                        </Box>
                    </Paper>
                </Box>
            </section>

                <p>All platforms will receive event messages in the following JSON format:</p>
                <CopyBlock
                    text={`{
    "event_name": string,
    "event_category": string,
    "timestamp": string,
    "aa_sdk_version": string,
    // ... additional event-specific parameters
}`}
                    language="json"
                    showLineNumbers={true}
                    theme={dracula}
                />
            </section>

            <section style={styles.section}>
                <Typography variant="h6" sx={{ mt: 4, color: "#0b3452", fontWeight: "bold" }}>
                    Basic Usage
                </Typography>
                <CopyBlock
                    text={`// Track any event
window.finvuClient.track('CONSENT_APPROVED', {
    fipCount: '2',
    accountCount: '3',
    fiTypes: ['DEPOSIT', 'RECURRING_DEPOSIT']
});`}
                    language="javascript"
                    showLineNumbers={true}
                    theme={dracula}
                />
            </section>

            <section style={styles.section}>
                <Typography variant="h6" sx={{ mt: 4, color: "#0b3452", fontWeight: "bold" }}>
                    Custom Events and Aliases
                </Typography>
                
                <Typography variant="subtitle1" sx={{ mt: 2, color: "#0b3452", fontWeight: "bold" }}>
                    Registering Custom Events
                </Typography>
                
                <CopyBlock
                    text={`// Register custom events
window.finvuClient.registerCustomEvents({
    CUSTOM_LOGIN_SUCCESS: {
        category: 'authentication',
        // other params
    },
    CUSTOM_ERROR: {
        category: 'error',
        // other params
    }
});

// Use custom events
window.finvuClient.track('LINKED_ACCOUNTS_DISCOVERED', {
    count: '2',
    fips: ['HDFC-FIP', 'ICICI-FIP']
});`}
                    language="javascript"
                    showLineNumbers={true}
                    theme={dracula}
                />

                <Typography variant="subtitle1" sx={{ mt: 2, color: "#0b3452", fontWeight: "bold" }}>
                    Using Event Aliases
                </Typography>
                
                <CopyBlock
                    text={`// Register aliases for standard events
window.finvuClient.registerAliases({
    'LOGIN_PAGE_LOADED': 'otp_page_loaded',
    'OTP_VERIFIED': 'otp_verification_success'
});

// Use either standard name or alias
window.finvuClient.track('otp_page_loaded', {
    duration: '1000', // in milliseconds
    deviceType: 'mobile'
});`}
                    language="javascript"
                    showLineNumbers={true}
                    theme={dracula}
                />
            </section>

            <section style={styles.section}>
                <Typography variant="h6" sx={{ mt: 4, color: "#0b3452", fontWeight: "bold" }}>
                    Default Parameters
                </Typography>
                <p>Every event tracked automatically includes these parameters:</p>
                <ul>
                    <li>event_name: The name of the event or its alias</li>
                    <li>event_category: The category the event belongs to</li>
                    <li>timestamp: ISO timestamp of when the event occurred</li>
                    <li>aa_sdk_version: Version of the AA SDK being used</li>
                </ul>
            </section>

            <section style={styles.section}>
                <Typography variant="h6" sx={{ mt: 4, color: "#0b3452", fontWeight: "bold" }}>
                    Event Categories
                </Typography>
                
                <Typography variant="subtitle1" sx={{ mt: 2, color: "#0b3452", fontWeight: "bold" }}>
                    Initialization Events
                </Typography>
                
                <table style={styles.table}>
                    <thead>
                        <tr>
                            <th style={styles.th}>Event Name</th>
                            <th style={styles.th}>Parameters</th>
                            <th style={styles.th}>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={styles.td}>WEBSOCKET_CONNECTED</td>
                            <td style={styles.td}>count</td>
                            <td style={styles.td}>WebSocket connection established successfully</td>
                        </tr>
                        <tr>
                            <td style={styles.td}>WEBSOCKET_DISCONNECTED</td>
                            <td style={styles.td}>count</td>
                            <td style={styles.td}>WebSocket connection closed or lost</td>
                        </tr>
                    </tbody>
                </table>

                <Typography variant="subtitle1" sx={{ mt: 2, color: "#0b3452", fontWeight: "bold" }}>
                    Redirection Events
                </Typography>
                
                <table style={styles.table}>
                    <thead>
                        <tr>
                            <th style={styles.th}>Event Name</th>
                            <th style={styles.th}>Parameters</th>
                            <th style={styles.th}>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={styles.td}>CONSENT_REQUEST_VALID</td>
                            <td style={styles.td}>count</td>
                            <td style={styles.td}>Consent request validation successful</td>
                        </tr>
                        <tr>
                            <td style={styles.td}>CONSENT_REQUEST_INVALID</td>
                            <td style={styles.td}>count</td>
                            <td style={styles.td}>Consent request validation failed</td>
                        </tr>
                    </tbody>
                </table>

                <Typography variant="subtitle1" sx={{ mt: 2, color: "#0b3452", fontWeight: "bold" }}>
                    OTP Events
                </Typography>
                
                <table style={styles.table}>
                    <thead>
                        <tr>
                            <th style={styles.th}>Event Name</th>
                            <th style={styles.th}>Parameters</th>
                            <th style={styles.th}>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={styles.td}>LOGIN_INITIATED</td>
                            <td style={styles.td}>count</td>
                            <td style={styles.td}>Login initiated</td>
                        </tr>
                        <tr>
                            <td style={styles.td}>LOGIN_OTP_GENERATED</td>
                            <td style={styles.td}>count</td>
                            <td style={styles.td}>Login OTP successfully generated and sent</td>
                        </tr>
                        <tr>
                            <td style={styles.td}>LOGIN_OTP_FAILED</td>
                            <td style={styles.td}>count</td>
                            <td style={styles.td}>Login OTP generation or sending failed</td>
                        </tr>
                        <tr>
                            <td style={styles.td}>LOGIN_OTP_LOCKED</td>
                            <td style={styles.td}>count</td>
                            <td style={styles.td}>Account locked after 3 failed OTP attempts</td>
                        </tr>
                        <tr>
                            <td style={styles.td}>LOGIN_OTP_VERIFIED</td>
                            <td style={styles.td}>count</td>
                            <td style={styles.td}>Login OTP verification successful</td>
                        </tr>
                        <tr>
                            <td style={styles.td}>LOGIN_OTP_NOT_VERIFIED</td>
                            <td style={styles.td}>count</td>
                            <td style={styles.td}>Login OTP verification failed</td>
                        </tr>
                    </tbody>
                </table>

                <Typography variant="subtitle1" sx={{ mt: 2, color: "#0b3452", fontWeight: "bold" }}>
                    Alternate Number Events
                </Typography>
                
                <table style={styles.table}>
                    <thead>
                        <tr>
                            <th style={styles.th}>Event Name</th>
                            <th style={styles.th}>Parameters</th>
                            <th style={styles.th}>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={styles.td}>ALTERANATE_NUMBER_ATTEMPTED</td>
                            <td style={styles.td}>count</td>
                            <td style={styles.td}>Alternate number verification attempt initiated</td>
                        </tr>
                        <tr>
                            <td style={styles.td}>ALTERANATE_NUMBER_OTP_GENERATED</td>
                            <td style={styles.td}>count</td>
                            <td style={styles.td}>OTP generated for alternate number verification</td>
                        </tr>
                        <tr>
                            <td style={styles.td}>ALTERANATE_NUMBER_OTP_FAILED</td>
                            <td style={styles.td}>count</td>
                            <td style={styles.td}>OTP generation failed for alternate number</td>
                        </tr>
                        <tr>
                            <td style={styles.td}>ALTERANATE_NUMBER_OTP_VERIFIED</td>
                            <td style={styles.td}>count</td>
                            <td style={styles.td}>Alternate number OTP verification successful</td>
                        </tr>
                        <tr>
                            <td style={styles.td}>ALTERANATE_NUMBER_OTP_NOT_VERIFIED</td>
                            <td style={styles.td}>count</td>
                            <td style={styles.td}>Alternate number OTP verification failed</td>
                        </tr>
                    </tbody>
                </table>

                <Typography variant="subtitle1" sx={{ mt: 2, color: "#0b3452", fontWeight: "bold" }}>
                    Discovery Events
                </Typography>
                
                <table style={styles.table}>
                    <thead>
                        <tr>
                            <th style={styles.th}>Event Name</th>
                            <th style={styles.th}>Parameters</th>
                            <th style={styles.th}>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={styles.td}>ACCOUNTS_DISCOVERED</td>
                            <td style={styles.td}>count</td>
                            <td style={styles.td}>Accounts successfully discovered at FIP</td>
                        </tr>
                        <tr>
                            <td style={styles.td}>DISCOVERY_FAILED</td>
                            <td style={styles.td}>count</td>
                            <td style={styles.td}>Discovery process failed at FIP</td>
                        </tr>
                        <tr>
                            <td style={styles.td}>ACCOUNTS_NOT_DISCOVERED</td>
                            <td style={styles.td}>count</td>
                            <td style={styles.td}>No accounts found or discovery failed</td>
                        </tr>
                    </tbody>
                </table>

                <Typography variant="subtitle1" sx={{ mt: 2, color: "#0b3452", fontWeight: "bold" }}>
                    Linking Events
                </Typography>
                
                <table style={styles.table}>
                    <thead>
                        <tr>
                            <th style={styles.th}>Event Name</th>
                            <th style={styles.th}>Parameters</th>
                            <th style={styles.th}>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={styles.td}>LINKING_INITIATED</td>
                            <td style={styles.td}>count</td>
                            <td style={styles.td}>Account linking process started</td>
                        </tr>
                        <tr>
                            <td style={styles.td}>LINKING_OTP_GENERATED</td>
                            <td style={styles.td}>count</td>
                            <td style={styles.td}>OTP generation initiated for account linking</td>
                        </tr>
                        <tr>
                            <td style={styles.td}>LINKING_OTP_FAILED</td>
                            <td style={styles.td}>count</td>
                            <td style={styles.td}>OTP generation failed during account linking</td>
                        </tr>
                        <tr>
                            <td style={styles.td}>LINKING_SUCCESS</td>
                            <td style={styles.td}>count</td>
                            <td style={styles.td}>Account successfully linked</td>
                        </tr>
                        <tr>
                            <td style={styles.td}>LINKING_FAILURE</td>
                            <td style={styles.td}>count</td>
                            <td style={styles.td}>Account linking failed</td>
                        </tr>
                    </tbody>
                </table>

                <Typography variant="subtitle1" sx={{ mt: 2, color: "#0b3452", fontWeight: "bold" }}>
                    Consent Events
                </Typography>
                
                <table style={styles.table}>
                    <thead>
                        <tr>
                            <th style={styles.th}>Event Name</th>
                            <th style={styles.th}>Parameters</th>
                            <th style={styles.th}>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={styles.td}>LINKED_ACCOUNTS_SUMMARY</td>
                            <td style={styles.td}>count, fips[], fiTypes[]</td>
                            <td style={styles.td}>Summary of accounts in consent approval</td>
                        </tr>
                        <tr>
                            <td style={styles.td}>CONSENT_APPROVED</td>
                            <td style={styles.td}>count</td>
                            <td style={styles.td}>User approved the consent request</td>
                        </tr>
                        <tr>
                            <td style={styles.td}>CONSENT_DENIED</td>
                            <td style={styles.td}>count</td>
                            <td style={styles.td}>User denied the consent request</td>
                        </tr>
                    </tbody>
                </table>

                <Typography variant="subtitle1" sx={{ mt: 2, color: "#0b3452", fontWeight: "bold" }}>
                    Error Events
                </Typography>
                
                <table style={styles.table}>
                    <thead>
                        <tr>
                            <th style={styles.th}>Event Name</th>
                            <th style={styles.th}>Parameters</th>
                            <th style={styles.th}>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={styles.td}>SESSION_ERROR</td>
                            <td style={styles.td}>count</td>
                            <td style={styles.td}>Session error during WebSocket connection</td>
                        </tr>
                        <tr>
                            <td style={styles.td}>SESSION_FAILURE</td>
                            <td style={styles.td}>count</td>
                            <td style={styles.td}>Response failure from server during session</td>
                        </tr>
                    </tbody>
                </table>
            </section>

            <section style={styles.section}>
                <Typography variant="h6" sx={{ mt: 4, color: "#0b3452", fontWeight: "bold" }}>
                    Best Practices
                </Typography>
                
                <Typography variant="subtitle1" sx={{ mt: 2, color: "#0b3452", fontWeight: "bold" }}>
                    Event Names
                </Typography>
                
                <ul>
                    <li>Use predefined event names or configured aliases only</li>
                    <li>Event names are case-sensitive</li>
                    <li>When creating custom events, follow a underscore separated format</li>
                    <li>Keep custom event names descriptive and specific</li>
                </ul>

                <Typography variant="subtitle1" sx={{ mt: 2, color: "#0b3452", fontWeight: "bold" }}>
                    Parameters
                </Typography>
                
                <ul>
                    <li>Optional parameters improve event context</li>
                    <li>Don't include non-compliant information</li>
                    <li>Don't include PII (Personal Identifiable Information)</li>
                    <li>For custom events, document the parameters clearly</li>
                </ul>

                <Typography variant="subtitle1" sx={{ mt: 2, color: "#0b3452", fontWeight: "bold" }}>
                    Error Handling
                </Typography>
                
                <ul>
                    <li>Library handles errors internally</li>
                    <li>Check console for warnings/errors during development</li>
                    <li>Custom events will be validated against their defined configuration</li>
                </ul>

                <Typography variant="subtitle1" sx={{ mt: 2, color: "#0b3452", fontWeight: "bold" }}>
                    Performance
                </Typography>
                
                <ul>
                    <li>Events are sent asynchronously</li>
                    <li>No impact on application performance</li>
                    <li>Batch custom event registrations when possible</li>
                </ul>

                <Typography variant="subtitle1" sx={{ mt: 2, color: "#0b3452", fontWeight: "bold" }}>
                    Aliases
                </Typography>
                
                <ul>
                    <li>Aliases must be configured through the proper configuration process</li>
                    <li>Use aliases consistently across your application</li>
                    <li>Document aliases in your codebase</li>
                    <li>Avoid using the same alias for different events</li>
                </ul>

                <Typography variant="subtitle1" sx={{ mt: 2, color: "#0b3452", fontWeight: "bold" }}>
                    Custom Events
                </Typography>
                
                <ul>
                    <li>Register all custom events during initialization</li>
                    <li>Follow the same category structure as standard events</li>
                    <li>Don't duplicate existing standard events</li>
                    <li>Keep custom event configurations minimal and focused</li>
                </ul>
            </section>

            <section style={styles.section}>
                <Typography variant="h6" sx={{ mt: 4, color: "#0b3452", fontWeight: "bold" }}>
                    Security Considerations
                </Typography>
                
                <Typography variant="subtitle1" sx={{ mt: 2, color: "#0b3452", fontWeight: "bold" }}>
                    PII Data
                </Typography>
                
                <ul>
                    <li>Never include PII in event parameters</li>
                    <li>No PAN, DOB, or account numbers</li>
                    <li>Use counts and types instead of specific details</li>
                </ul>

                <Typography variant="subtitle1" sx={{ mt: 2, color: "#0b3452", fontWeight: "bold" }}>
                    Sensitive Data
                </Typography>
                
                <ul>
                    <li>Be mindful to not track any sensitive customer data</li>
                    <li>Don't track session tokens</li>
                    <li>Don't track account numbers not given consent for</li>
                </ul>

                <Typography variant="subtitle1" sx={{ mt: 2, color: "#0b3452", fontWeight: "bold" }}>
                    Pre-Consent Data
                </Typography>
                
                <ul>
                    <li>Don't track specific FIP identifiers before consent</li>
                    <li>Don't track account types before consent</li>
                    <li>Only track aggregate counts where necessary</li>
                    <li>When in doubt, err on the side of privacy</li>
                </ul>

                <Typography variant="subtitle1" sx={{ mt: 2, color: "#0b3452", fontWeight: "bold" }}>
                    Data Transmission
                </Typography>
                
                <ul>
                    <li>All events are transmitted over secure channels</li>
                    <li>Events are validated before transmission</li>
                    <li>Failed events are logged but not retried</li>
                </ul>

                <Typography variant="subtitle1" sx={{ mt: 2, color: "#0b3452", fontWeight: "bold" }}>
                    Access Control
                </Typography>
                
                <ul>
                    <li>Only authorized platforms can receive events</li>
                    <li>Origin verification for iframe integration</li>
                    <li>Platform-specific message handlers</li>
                </ul>
            </section>

            <section style={styles.section}>
                <Typography variant="h6" sx={{ mt: 4, color: "#0b3452", fontWeight: "bold" }}>
                    Support
                </Typography>
                <ul>
                    <li>Technical Support: <a href="mailto:support@cookiejar.co.in">support@cookiejar.co.in</a></li>
                    <li>Documentation: <a href="https://docs.finvu.in">https://docs.finvu.in</a></li>
                    <li>SDK Version: 2.1.0</li>
                    <li>Tracking Library Version: 1.0.0</li>
                </ul>
            </section>

            <section style={styles.section}>
                <Typography variant="h6" sx={{ mt: 4, color: "#0b3452", fontWeight: "bold" }}>
                    Changelog
                </Typography>
                
                <Typography variant="subtitle1" sx={{ mt: 2, color: "#0b3452", fontWeight: "bold" }}>
                    Version 1.0.0
                </Typography>
                <ul>
                    <li>Initial release</li>
                    <li>Basic event tracking functionality</li>
                    <li>Standard event definitions (29 built-in events)</li>
                    <li>Custom event registration support</li>
                    <li>Event aliasing capabilities</li>
                    <li>Cross-platform support (Web, React Native, Android, iOS)</li>
                </ul>
            </section>
        </Box>
    );

    const getNextTabPath = (currentTab: number) => {
        if (currentTab === 0) {
            return '/web-sdk-integration#event-tracking';
        }
        return '/mobile-sdk-integration';
    };

    const getPrevTabPath = (currentTab: number) => {
        if (currentTab === 1) {
            return '/web-sdk-integration#sdk';
        }
        return '/api-integration#frontend';
    };

    const TabNavigation = ({ currentTab }: { currentTab: number }) => (
        <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between',
            mt: 6,
            mb: 2,
            borderTop: '1px solid #e0e0e0',
            pt: 4
        }}>
            <Button 
                startIcon={<ArrowBackIcon />}
                onClick={() => navigate(getPrevTabPath(currentTab))}
                sx={{ color: '#0b3452' }}
            >
                {currentTab === 1 ? 'SDK Integration' : 'Frontend Integration'}
            </Button>
            <Button 
                endIcon={<ArrowForwardIcon />}
                onClick={() => navigate(getNextTabPath(currentTab))}
                sx={{ color: '#0b3452' }}
            >
                {currentTab === 0 ? 'Event Tracking' : 'Mobile SDK Integration'}
            </Button>
        </Box>
    );

    return (
        <Box sx={{ paddingLeft: 4 }}>
            <Typography
                sx={{
                    fontWeight: 'bold',
                    fontSize: { xs: "1.2rem", sm: "1.5rem" },
                    color: "#0b3452",
                    display: 'inline-block',
                    marginBottom: 2,
                    lineHeight: 1.5,
                }}
            >
                Web SDK Integration
            </Typography>

            <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    marginBottom: 3
                }}
            >
                <Tab label="SDK Integration" />
                <Tab label="Event Tracking" />
            </Tabs>

            {selectedTab === 0 ? <SDKContent /> : <EventTrackingContent />}

            <TabNavigation currentTab={selectedTab} />
        </Box>
    );
});

export default WebSDKIntegration;
